import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import GlobalStyle from './styles/GlobalStyle'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBMb1iZwrdckamTFpAKq4cRAVvMC88AuGs",
  authDomain: "innopm-a4a1d.firebaseapp.com",
  projectId: "innopm-a4a1d",
  storageBucket: "innopm-a4a1d.appspot.com",
  messagingSenderId: "28144844817",
  appId: "1:28144844817:web:1cb05e6ca265cc3f4131b0",
  measurementId: "G-B9S7CFZ013"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
