import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { mediaQuery } from '../../theme/breakpoints'
import Section from '../common/Section'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import CheckListIcon from '@images/home/checkListIcon.png'

const Title = styled(Typography)`
  color: #c5b89c;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4em;
  word-wrap: break-word;
  word-break: keep-all;
  ${mediaQuery[0]} {
    font-size: 35px;
  }
`

const PointList = styled.ul`
  max-width: 750px;
  margin: 40px auto 0 auto;
  ${mediaQuery[0]} {
    margin: 70px auto 0 auto;
  }
`

const Point = styled.li`
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
  padding-bottom: 10px;
  list-style-type: none;
  border-bottom: 0.5px solid var(--color-grey-1);

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4em;
    word-wrap: break-word;
    word-break: keep-all;
    color: var(--color-white);
    ${mediaQuery[0]} {
      font-size: 18px;
    }

    strong {
      font-weight: 500;
    }
  }
`

const CheckBullet = styled.img`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-bottom: -4px;
  margin-right: 5px;
  ${mediaQuery[0]} {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
`

const CheckList = () => {
  return (
    <Section sx={{ background: '#1C1C1C' }}>
      <CenterAlignerWithXPadding>
        <Title variant="h5">
          다음 중 2가지 이상이 해당된다면,
          <br />
          지금이 InnoPM 컨설팅이 필요한 때에요.
        </Title>
        <PointList>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              1. 좋은 아이디어를 가지고 있지만 IT 경험이 없어서 <strong>어떻게 시작해야 할지</strong> 막막하다.
            </Typography>
          </Point>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              2. 웹 또는 앱을 개발하기 위한 <strong>외주 개발팀을 찾는 것이 어려워서</strong> 도움이 필요하다.
            </Typography>
          </Point>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              3. <strong>개발 프로세스와 작업 흐름</strong>을 구체적으로 정의하고 싶은데 방법을 잘 모르겠다.
            </Typography>
          </Point>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              4. <strong>어떤 플랫폼(웹, 앱, 네이티브)을 선택</strong>해야 하는지 고민이다.
            </Typography>
          </Point>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              5. 내부개발자 혹은 외주 개발 업체와의 <strong>의사소통과 협업에 어려움</strong>을 겪고 있어 도움이
              필요하다.
            </Typography>
          </Point>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              6. 제한된 예산 내에서 <strong>개발비용을 효율적으로 사용</strong>하는데 고민이 된다.
            </Typography>
          </Point>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              7. 뻔한 스타트업용어를 남발하는 내용보다, <strong>쉽게 이해할 수 있는 맞춤 상담</strong>이 필요하다.
            </Typography>
          </Point>
          <Point>
            <Typography>
              <CheckBullet src={CheckListIcon} />
              8. IT기획자나 서비스 개발자에게 직접 물어보고 싶은 <strong>실무관련 궁금증 해소</strong>가 필요하다.
            </Typography>
          </Point>
        </PointList>
      </CenterAlignerWithXPadding>
    </Section>
  )
}

export default CheckList
