import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import Section from '../common/Section'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import MiddleBannerBg from '@images/home/middleBannerBg.jpg'

const Desc = styled(Typography)`
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4em;
  word-wrap: break-word;
  word-break: keep-all;
  text-align: right;
  color: #242424;
  ${mediaQuery[0]} {
    font-size: 40px;
  }
`

const MiddleBanner = () => {
  return (
    <>
      <Section sx={{ background: `url(${MiddleBannerBg}) no-repeat center / cover` }}>
        <CenterAlignerWithXPadding>
          <Desc>
            웹/앱 비즈니스 아이디어가
            <br />
            현실이 되는 지름길로 안내해요.
          </Desc>
        </CenterAlignerWithXPadding>
      </Section>
    </>
  )
}

export default MiddleBanner
