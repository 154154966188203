import React from 'react'
import { RouterProvider, createBrowserRouter, Outlet, ScrollRestoration } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import MobileFooter from './components/common/MobileFooter'
import MobileAppBar from './components/common/MobileAppBar'
import DesktopFooter from './components/common/DesktopFooter'
import DesktopAppBar from './components/common/DesktopAppBar'
import Application from './pages/Application'
import FreeConsultingEvent from './pages/FreeConsultingEvent'

export const routes = [
  {
    path: '/',
    name: 'Home',
    element: <Home />,
    isInHeader: true,
  },
  {
    path: '/consultationApplication',
    name: 'Application',
    element: <Application />,
    isInHeader: false,
  },
  {
    path: '/freeConsultingEvent',
    name: 'Event',
    element: <FreeConsultingEvent />,
    isInHeader: true,
  },
]

const Layout: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:769px)')
  const [appBar, setAppBar] = useState<ReactNode>()
  const [footer, setFooter] = useState<ReactNode>()

  useEffect(() => {
    if (isMobile) {
      setFooter(<MobileFooter />)
      setAppBar(<MobileAppBar />)
      return
    }
    setFooter(<DesktopFooter />)
    setAppBar(<DesktopAppBar />)
  }, [isMobile])

  return (
    <div className="App">
      {appBar}
      <Outlet />
      <ScrollRestoration />
      {footer}
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: routes,
  },
])

const theme = createTheme({
  typography: {
    fontFamily: "'Spoqa Han Sans Neo', sans-serif",
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
