import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import InnoPMLogo from '@images/InnoPMLogo.png'
import { routes } from '../../App'

const DesktopAppBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const navigationItems = routes.filter(route => route.isInHeader)

  const onClickCounselling = () => {
    navigate('/consultationApplication')
  }

  return (
    <AppBar sx={{ alignItems: 'center', backgroundColor: '#fff' }} elevation={0}>
      <Toolbar sx={{ width: '100%', maxWidth: 1220, padding: '12px 0px' }}>
        <Link to={'/'} style={{ display: 'flex', alignItems: 'center', height: '44px' }}>
          <img src={InnoPMLogo} alt={'logo'} style={{ cursor: 'pointer', filter: 'invert(1)', width: '206px' }} />
        </Link>
        <Box sx={{ marginLeft: 5, flex: 1, flexDirection: 'row', display: 'flex', gap: '28px' }}>
          {navigationItems.length > 1 &&
            navigationItems.map(route => (
              <Typography
                sx={{
                  fontWeight: location.pathname === route.path ? 700 : 400,
                  fontSize: 16,
                  lineHeight: '22.4px',
                  cursor: 'pointer',
                  color: '#3d3d3d',
                }}
                onClick={() => navigate(route.path)}
              >
                {route.name}
              </Typography>
            ))}
        </Box>

        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} gap={0.75}>
            <Button
              variant={'contained'}
              sx={{
                whiteSpace: 'nowrap',
                ml: '28px',
                padding: '4px 20px',
                fontSize: 14,
                lineHeight: '24px',
                fontWeight: 600,
              }}
              onClick={onClickCounselling}
            >
              맞춤상담신청
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default DesktopAppBar
