import styled from '@emotion/styled'
import { Box } from '@mui/material'

const CenterAlignerWithXPadding = styled(Box)`
  width: 100%;
  max-width: var(--max-inner-width);
  margin: 0 auto;
  padding: 0 20px;
`

export default CenterAlignerWithXPadding
