import { Box, Typography } from '@mui/material'
import InnoPMLogo from '@images/InnoPMLogo.png'

const MobileFooter = () => {
  return (
    <Box
      className="footer"
      sx={{
        display: 'flex',
        alignItems: 'start',
        backgroundColor: '#fff',
        justifyContent: 'center',
        padding: '40px 30px',
        width: '100%',
        flexDirection: 'column',
        '.recruit + &': {
          paddingBottom: '120px',
        },
        textAlign: 'left',
      }}
    >
      <img src={InnoPMLogo} alt={'logo'} style={{ cursor: 'pointer', filter: 'invert(1)', height: '16px' }} />
      <Typography variant={'body2'} sx={{ color: '#8B8B8B', fontSize: 15 }} marginTop={3}>
        (주)온보더즈{<br />}
      </Typography>
      <Typography sx={{ color: '#8B8B8B', whiteSpace: 'pre-line' }} marginTop={2}>
        {`대표자: 김진하
              사업자등록번호: 696-88-01905
              본사: 서울특별시 은평구 녹번로 6-24, 비동 5층 501호 (녹번동, 펠리체)
              기업부설연구소: 서울 강남구 테헤란로78길 14-6 4층 에그스테이션 607호
              `}
      </Typography>
    </Box>
  )
}

export default MobileFooter
