import { Box, Typography, keyframes } from '@mui/material'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'

const glow = keyframes`
  0% {
    box-shadow: 0px 0px 5px rgba(255, 230, 239, 0.5), 0px 0px 20px rgba(255, 255, 255, 0.25);
    background-color: #ffd9e7;
  }
  30% {
    background-color: #ffd9e7;
  }
  100% {
    box-shadow: 0px 0px 0px rgba(255, 230, 239, 0.5), 0px 0px 0px rgba(255, 255, 255, 0.25);
    background-color: #ff0058;
  }
`

const ConsultingStep = styled(Box)`
  position: relative;
  min-height: 44px;

  ${mediaQuery[0]} {
    min-height: 6vw;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 6.5px;
    display: block;
    width: 1px;
    height: 100%;
    background-color: #ffd9e7;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 14px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #ffd9e7;
  }

  &:last-of-type {
    min-height: unset;
    &::before {
      display: none;
    }
  }

  &.highlight {
    &::after {
      border: 3px solid #ff0058;
      box-sizing: border-box;
      box-shadow:
        0px 0px 5px rgba(255, 230, 239, 0.5),
        0px 0px 20px rgba(255, 255, 255, 0.25);
      animation: ${glow} 1s infinite alternate;
    }
    p {
      opacity: 1;
    }
  }
`

const ConsultingStepTxt = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-left: 30px;
  word-wrap: break-word;
  word-break: keep-all;
  padding: 0 16px 16px 0;

  ${mediaQuery[0]} {
    font-size: 16px;
  }
`

const ConsultingSteps = () => {
  return (
    <>
      <Box>
        <ConsultingStep className="highlight">
          <ConsultingStepTxt>1. NDA 및 컨설팅 계약 체결</ConsultingStepTxt>
        </ConsultingStep>
        <ConsultingStep>
          <ConsultingStepTxt>2. 자료 취합 및 사전 분석</ConsultingStepTxt>
        </ConsultingStep>
        <ConsultingStep>
          <ConsultingStepTxt>3. 컨설턴트 연결 및 일정 조율</ConsultingStepTxt>
        </ConsultingStep>
        <ConsultingStep>
          <ConsultingStepTxt>4. 컨설팅 진행</ConsultingStepTxt>
        </ConsultingStep>
        <ConsultingStep>
          <ConsultingStepTxt>5. 결과리포트 제공</ConsultingStepTxt>
        </ConsultingStep>
        <ConsultingStep>
          <ConsultingStepTxt>6. 사후관리</ConsultingStepTxt>
        </ConsultingStep>
      </Box>
    </>
  )
}

export default ConsultingSteps
