import { Box, Typography } from '@mui/material'
import CenterAlignerWithXPadding from './CenterAlignerWithXPadding'
import InnoPMLogo from '@images/InnoPMLogo.png'

const DesktopFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        '.recruit + &': {
          paddingBottom: '120px',
        },
        textAlign: 'left',
      }}
    >
      <CenterAlignerWithXPadding>
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#fff',
            justifyContent: 'space-between',
            paddingY: '3%',
            width: '100%',
            gap: '30px',
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
            <img src={InnoPMLogo} alt={'logo'} style={{ cursor: 'pointer', filter: 'invert(1)', height: '30px' }} />
          </Box>
          <Box>
            <Typography variant={'body2'} sx={{ color: '#8B8B8B' }}>
              (주)온보더즈{<br />}
            </Typography>
            <Typography sx={{ color: '#8B8B8B', whiteSpace: 'pre-line' }}>
              {`대표자: 김진하
              사업자등록번호: 696-88-01905
              본사: 서울특별시 은평구 녹번로 6-24, 비동 5층 501호 (녹번동, 펠리체)
              기업부설연구소: 서울 강남구 테헤란로78길 14-6 4층 에그스테이션 607호
              `}
            </Typography>
          </Box>
        </Box>
      </CenterAlignerWithXPadding>
    </Box>
  )
}

export default DesktopFooter
