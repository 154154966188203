import { Box } from '@mui/material'
import Intro from '../components/home/Intro'
import CheckList from '../components/home/CheckList'
import ServicePoints from '../components/home/ServicePoints'
import ConsultingProcess from '../components/home/ConsultingProcess'
import MiddleBanner from '../components/home/MiddleBanner'
import ConsultantCareer from '../components/home/ConsultantCareer'
import Price from '../components/home/Price'
import EndBanner from '../components/home/EndBanner'

const Home = () => {
  return (
    <Box bgcolor={'#fff'} minHeight={'100vh'}>
      <Intro />
      <CheckList />
      <ServicePoints />
      <ConsultingProcess />
      <MiddleBanner />
      <ConsultantCareer />
      <Price />
      <EndBanner />
    </Box>
  )
}

export default Home
