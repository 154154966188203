import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import { Box } from '@mui/material'

const Section = styled(Box)`
  width: 100%;
  padding: 60px 0;
  ${mediaQuery[0]} {
    padding: 120px 0;
  }

  &:nth-of-type(1) {
    margin-top: var(--mobile-header-height);

    ${mediaQuery[0]} {
      margin-top: var(--pc-header-height);
    }
  }
`

export default Section
