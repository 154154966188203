import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import Section from '../common/Section'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import PriceTable from '@images/home/priceTable.jpg'

const SubTitle = styled(Typography)`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4em;
  color: var(--color-grey-3);
  ${mediaQuery[0]} {
    margin-bottom: 10px;
    font-size: 24px;
  }
`

const SectionTitle = styled(Typography)`
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4em;
  word-break: keep-all;
  word-wrap: break-word;
  color: var(--color-grey-dark-2);

  ${mediaQuery[0]} {
    font-size: 52px;
  }
`

const Notice = styled.span`
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #e61f57;
  ${mediaQuery[0]} {
    margin-bottom: 100px;
    font-size: 20px;
  }
`

const ScrollArea = styled(Box)`
  width: 100%;
  padding: 0 20px;
  overflow-x: auto;
`

const TableWrapper = styled(Box)`
  position: relative;
  width: 100%;
  min-width: 700px;
  overflow-x: auto;
  aspect-ratio: 2.39;
`
const MenualTitle = styled(Box)`
  margin: 30px 0 10px 0;
  font-size: 16px;
  line-height: 1.4em;
  font-weight: 600;
`
const MenualDesc = styled(Box)`
  font-size: 14px;
  line-height: 1.4em;
  color: var(--color-grey-2);
  word-wrap: break-word;
  word-break: keep-all;

  ul,
  ol {
    margin-left: 3%;
  }
`

const Price = () => {
  return (
    <>
      <Section sx={{ textAlign: 'left' }}>
        <CenterAlignerWithXPadding>
          <SubTitle>Price</SubTitle>
          <SectionTitle>비용안내</SectionTitle>
          <Notice>컨설팅 서비스 이용 후 온보더즈 IT코스 이용시 결제금액 공제</Notice>
        </CenterAlignerWithXPadding>
        <CenterAlignerWithXPadding style={{ padding: 0 }}>
          <ScrollArea>
            <TableWrapper>
              <Box
                sx={{ width: '100%', height: '100%', background: `url(${PriceTable}) no-repeat  center / cover ` }}
              />
            </TableWrapper>
          </ScrollArea>
        </CenterAlignerWithXPadding>
        <CenterAlignerWithXPadding>
          <MenualTitle>유의사항</MenualTitle>
          <MenualDesc>
            온보더즈에서는 기업의 성장에 필요한 다양한 IT 코스를 제공하고 있습니다. 추후 온보더즈 IT코스를 이용하실 경우
            결제시 InnoPM consulting 상품 결제 금액을 1회 공제하실 수 있습니다. 자세한 사항은 언제든지 문의해주세요.
            감사합니다.
            <ul>
              <li>
                가격은 Lite, Pro, Professional 등 상품에 따라 나뉘며, 상담 시간, 컨설팅 범위, 리포트 형태, 상담 형태
                등이 각각 다를 수 있습니다.
              </li>
              <li>결제는 계좌이체만 가능하며, 세금계산서/현금영수증 발행이 가능합니다.</li>
              <li>
                고객님의 비즈니스와 관련된 모든 정보는 기밀성을 보장받으며, 컨설팅 시간과 방법은 계약 시 상담 가능한
                시간과 방식으로 조율됩니다.
              </li>
              <li>
                상담을 원하실 경우, 사전 예약은 필수이며, 컨설팅 서비스를 통해 얻은 정보와 결과는 고객님의 비즈니스
                발전을 위한 목적으로만 사용됩니다.
              </li>
            </ul>
            <ol>
              <li>
                취소 정책:
                <ol>
                  <li>서비스를 예약한 후, 사전 예약 취소를 원하실 경우 최소 24시간 전에 알려주셔야 합니다.</li>
                  <li>24시간 이내에 취소 요청 시, 취소 수수료가 발생합니다.</li>
                  <li>예약 후 취소 요청은 이메일이나 전화를 통해 접수하실 수 있습니다.</li>
                </ol>
              </li>
              <li>
                환불 정책:
                <ol>
                  <li>컨설팅/상담이 완료된 이후에는 환불이 불가능 합니다.</li>
                  <li>
                    컨설턴트의 귀책사유로 당초 약정했던 서비스를 이행하지 않건, 보편적인 관점에서 심각하게 잘못 이행한
                    경우 결제 금액 전체 환불이 가능합니다.
                  </li>
                  <li>
                    상담도중 의뢰인의 귀책사유로 인해 환불을 요청할 경우, 사용금액을 아래와 같이 계산 후 총 결제금액의
                    10%를 공제한 후 환불합니다.
                    <ol>
                      <li>총 상담시간의 1/2경과 전 : 이미 납부한 요금의 1/2 해당액</li>
                      <li>총 상담시간의 1/2경과 전 : 환불 불가</li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                예외 상황:
                <ol>
                  <li>
                    서비스 이용 중 발생한 문제 또는 불만사항에 대해서는 적절한 조치를 취하고 문제 해결을 위해
                    노력합니다.
                  </li>
                  <li>예외 상황의 환불 규정은 별도로 협의되며, 상황에 따라 조정될 수 있습니다.</li>
                  <li>
                    상담 / 컨설팅 진행 중 의뢰인의 폭언, 욕설 등이 있을 경우 상담은 즉시 종료되며, 잔여 이용요금은 환불
                    되지 않습니다.
                  </li>
                </ol>
              </li>
            </ol>
            취소 및 환불 규정은 고객의 권익과 합리적인 서비스 운영을 고려하여 설정되었습니다. 더 자세한 내용이
            필요하거나 질문이 있으시면 언제든지 문의해주십시오.
          </MenualDesc>
        </CenterAlignerWithXPadding>
      </Section>
    </>
  )
}

export default Price
