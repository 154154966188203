import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import Section from '../common/Section'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import { Link } from 'react-router-dom'
import InnoPMLogo from '@images/InnoPMLogo.png'
import IntroBg from '@images/home/introBg.jpg'

const Logo = styled(Box)`
  position: relative;
  width: 50vw;
  max-width: 500px;
  aspect-ratio: 8.55;
`

const Desc = styled(Typography)`
  text-align: left;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4em;
  color: #fff;
  ${mediaQuery[0]} {
    margin-top: 40px;
    font-size: 30px;
  }
`

const ToApplyButton = styled.div`
  display: inline-block;
  padding: 12px 28px;
  margin-top: 35px;
  border-radius: 25px;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  text-align: left;

  ${mediaQuery[0]} {
    padding: 14px 32px;
    margin-top: 70px;
    border-radius: 25px;
    font-size: 16px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`

const Intro = () => {
  return (
    <Section sx={{ background: `url(${IntroBg}) no-repeat center / cover` }}>
      <CenterAlignerWithXPadding>
        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
          <Logo>
            <img src={InnoPMLogo} alt="InnoPM Consulting" style={{ width: '100%' }} />
          </Logo>
          <Desc>
            현실적인 솔루션으로
            <br />
            성공적인 출발을 돕는
            <br />
            IT비즈니스 전문 컨설팅 서비스
          </Desc>
          <ToApplyButton>
            <Link to="/consultationApplication">컨설팅 신청 바로가기</Link>
          </ToApplyButton>
        </Box>
      </CenterAlignerWithXPadding>
    </Section>
  )
}

export default Intro
