import { useEffect, useRef } from 'react'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'

const ConsultationApplication = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    iframeRef.current.style.height = '100vh'
  }, [])
  return (
    <main style={{ backgroundColor: '#fff' }}>
      <CenterAlignerWithXPadding style={{ paddingTop: 100, display: 'flex', justifyContent: 'center' }}>
        <iframe
          ref={iframeRef}
          width="100%"
          src="https://form.asana.com/?k=dlWrhBXQJGKs5cVYqN1cAw&d=1201535400243418&embed=true"
          style={{ border: 0 }}
          title="InnoPM 상담신청 양식"
        />
      </CenterAlignerWithXPadding>
    </main>
  )
}

export default ConsultationApplication
