import Title from '../components/freeConsultingEvent/Title'
import Section from '../components/common/Section'
import Content from '../components/freeConsultingEvent/Content'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const FreeConsultingEvent = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Section
      sx={{
        paddingTop: '0 !important',
        background:
          'linear-gradient(180deg, rgba(37, 10, 65, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #250A41 0%, #5F1AA7 100%)',
      }}
    >
      <Title />
      <Content />
    </Section>
  )
}

export default FreeConsultingEvent
