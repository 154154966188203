import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import Section from '../common/Section'
import CareerMobile from '@images/home/careerMobile.png'
import CareerPc from '@images/home/careerPc.png'

const Inner = styled(Box)`
  position: relative;
  margin: 0 auto;
  max-width: 750px;
  padding: 0 20px;
  ${mediaQuery[0]} {
  }

  img {
    width: 100%;
    height: auto;
    &.pc {
      display: none;
      ${mediaQuery[0]} {
        display: block;
      }
    }

    &.mobile {
      ${mediaQuery[0]} {
        display: none;
      }
    }
  }
`

const ConsultantCareer = () => {
  return (
    <>
      <Section sx={{ background: '#141414' }}>
        <Inner>
          <img className="mobile" src={CareerMobile} alt="" />
          <img className="pc" src={CareerPc} alt="" />
        </Inner>
      </Section>
    </>
  )
}

export default ConsultantCareer
