import { Box } from '@mui/material'
import TitleImage from '@images/freeConsultingEvent/title.png'

const Title = () => {
  return (
    <>
      <Box
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
        sx={{
          backgroundImage: `url('${TitleImage}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '-7px',
          backgroundSize: 'cover',
          aspectRatio: 1.564,
          maxWidth: '900px',
          margin: '0 auto',
        }}
      ></Box>
    </>
  )
}
export default Title
