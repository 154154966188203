import { css } from '@emotion/react'

export const colorVariables = css`
  --color-red-light: #fff2f7;
  --color-red-light-1: #ffd9e7;
  --color-red-light-2: #ffb0cc;
  --color-red-normal: #ff0058;
  --color-red-normal-1: #e60053;
  --color-red-normal-2: #cc004a;
  --color-red-dark: #bf0045;
  --color-red-dark-1: #990037;
  --color-red-dark-2: #730029;
  --color-red-darker: #590020;

  --color-blue-light: #e6f0fd;
  --color-blue-light-1: #d9e8fb;
  --color-blue-light-2: #b0d0f8;
  --color-blue-normal: #1966e0;
  --color-blue-normal-1: #005ed0;
  --color-blue-normal-2: #0053b9;
  --color-blue-dark: #004ead;
  --color-blue-dark-1: #003e8b;
  --color-blue-dark-2: #002f68;
  --color-blue-darker: #002451;

  --color-white: #ffffff;
  --color-grey-light-1: #dfdfdf;
  --color-grey-light-2: #c1c1c1;
  --color-grey-1: #a5a5a5;
  --color-grey-2: #8b8b8b;
  --color-grey-3: #6f6f6f;
  --color-grey-4: #555555;
  --color-grey-dark-1: #3d3d3d;
  --color-grey-dark-2: #242424;
  --color-black: #000000;
`

export const layoutVariables = css`
  --mobile-header-height: 60px;
  --pc-header-height: 68px;

  --max-inner-width: 1200px;
`
