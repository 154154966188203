import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import ContentImagePC from '@images/freeConsultingEvent/contentPc.png'
import ContentImageMobile from '@images/freeConsultingEvent/contentMobile.png'
import EyeIcon from '@images/freeConsultingEvent/eyeIcon.png'
import ClickIcon from '@images/freeConsultingEvent/clickIcon.png'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

const PcImage = styled.img`
  display: none;
  ${mediaQuery[0]} {
    display: block;
  }
`

const MobileImage = styled.img`
  ${mediaQuery[0]} {
    display: none;
  }
`
const EventButton = styled.button`
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 28px;
  border-radius: 25px;
  border: 2px solid #937bfa;
  color: var(--color-white);
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
  text-align: left;
  background-color: #5843c0;
  width: 90%;
  text-align: center;

  ${mediaQuery[0]} {
    padding: 16px 32px;
    margin-top: 70px;
    border-radius: 25px;
    font-size: 22px;
    max-width: 500px;
    &:hover {
      background-color: #937bfa;
    }
  }
`

const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  ${mediaQuery[0]} {
    flex-direction: row;
  }
`

const Content = () => {
  const navigate = useNavigate()

  return (
    <CenterAlignerWithXPadding>
      <Box
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="800"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
      >
        <PcImage src={ContentImagePC} alt={''} style={{ width: '100%' }} />
        <MobileImage
          src={ContentImageMobile}
          alt={''}
          style={{ width: 'calc(100% + 50px)', transform: 'translateX(-25px)' }}
        />
      </Box>
      <ButtonWrapper>
        <EventButton
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          onClick={() => navigate('/')}
        >
          컨설팅 내용 자세히보기
          <img src={EyeIcon} style={{ width: '10%', minWidth: '30px' }} alt="eye icon" />
        </EventButton>
        <EventButton
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          onClick={() => navigate('/consultationApplication')}
        >
          컨설팅 신청 바로가기
          <img src={ClickIcon} style={{ width: '7%', minWidth: '25px' }} alt="click icon" />
        </EventButton>
      </ButtonWrapper>
    </CenterAlignerWithXPadding>
  )
}

export default Content
