import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import ConsultingSteps from './ConsultingSteps'
import { mediaQuery } from '../../theme/breakpoints'
import Section from '../common/Section'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import ProcessThumbnail from '@images/home/processThumbnail.jpg'

const Label = styled(Typography)`
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 16px;
  color: var(--color-white);
  ${mediaQuery[0]} {
    margin-bottom: 17px;
    font-size: 24px;
  }
`

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  color: #fff;

  ${mediaQuery[0]} {
    font-size: 52px;
  }
`

const ContsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 16px;
  margin-top: 32px;

  ${mediaQuery[0]} {
    flex-direction: row;
    gap: 5vw;
    padding: 0;
    margin-top: 80px;
  }
`

const ThumbnailImg = styled(Box)`
  width: 100%;
  aspect-ratio: 1.86;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;

  ${mediaQuery[0]} {
    width: 62vw;
    border-radius: unset;
  }
`

const Notice = styled.span`
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: 300;
  font-size: 15px;
  text-align: center;
  color: #fff;
  opacity: 0.8;
  ${mediaQuery[0]} {
    margin-top: 30px;
    font-size: 20px;
  }
`

const ConsultingProcess = () => {
  return (
    <>
      <Section sx={{ backgroundColor: '#C5B89C', textAlign: 'left' }}>
        <CenterAlignerWithXPadding>
          <Label>Process</Label>
          <Title variant="h2">이렇게 진행돼요</Title>
        </CenterAlignerWithXPadding>
        <ContsWrapper>
          <ThumbnailImg
            sx={{
              backgroundImage: `url('${ProcessThumbnail}')`,
            }}
          />
          <ConsultingSteps />
        </ContsWrapper>
        <Notice>*Professional 상품 기준</Notice>
      </Section>
    </>
  )
}

export default ConsultingProcess
