import { useState } from 'react'
import { AppBar, Box, Button, Collapse, Icon, IconButton, ListItemButton, ListItemText, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@images/icMenu.png'
import CloseIcon from '@images/icClose.png'
import InnoPMLogo from '@images/InnoPMLogo.png'
import { routes } from '../../App'

const MobileAppBar = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const navigationItems = routes.filter(route => route.isInHeader)

  const onClickCounselling = () => {
    navigate('/consultationApplication')
  }

  return (
    <AppBar sx={{ backgroundColor: '#fff' }} elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between', padding: '2px 20px', minHeight: 60 }}>
        <img
          src={InnoPMLogo}
          alt={'logo'}
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer', filter: 'invert(1)', width: '150px' }}
        />
        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Box>
            <Button
              variant={'contained'}
              sx={{
                whiteSpace: 'nowrap',
                ml: '28px',
                padding: '4px 20px',
                fontSize: 14,
                lineHeight: '24px',
                fontWeight: 600,
              }}
              onClick={onClickCounselling}
            >
              맞춤상담신청
            </Button>
          </Box>
          {navigationItems.length > 1 && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(!open)}
              sx={{ marginRight: -1.5 }}
            >
              <Icon>
                {open ? (
                  <img src={CloseIcon} width={24} height={24} alt={'close'} color={'#202123'} />
                ) : (
                  <img src={MenuIcon} width={24} height={24} alt={'menu'} color={'#202123'} />
                )}
              </Icon>
            </IconButton>
          )}
        </Box>
      </Toolbar>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {navigationItems.map(route => (
          <ListItemButton sx={{ height: 60 }} onClick={() => navigate(route.path)}>
            <ListItemText primary={route.name} primaryTypographyProps={{ fontSize: 15, color: '#202123' }} />
          </ListItemButton>
        ))}
      </Collapse>
    </AppBar>
  )
}

export default MobileAppBar
