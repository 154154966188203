import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import Section from '../common/Section'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import DoubleQuote from '@images/home/doubleQuote.png'
import ServicePoint01 from '@images/home/servicePoint01.png'
import ServicePoint02 from '@images/home/servicePoint02.jpg'
import ServicePoint03 from '@images/home/servicePoint03.jpg'
import ServicePoint04 from '@images/home/servicePoint04.jpg'
import ServicePoint05 from '@images/home/servicePoint05.jpg'
import ServicePoint06 from '@images/home/servicePoint06.jpg'
import ServicePoint07 from '@images/home/servicePoint07.png'
import ServicePoint08 from '@images/home/servicePoint08.jpg'
import ServicePoint09 from '@images/home/servicePoint09.jpg'

const SubTitle = styled(Typography)`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4em;
  color: var(--color-grey-3);
  ${mediaQuery[0]} {
    margin-bottom: 10px;
    font-size: 24px;
  }
`

const SectionTitle = styled(Typography)`
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4em;
  word-break: keep-all;
  word-wrap: break-word;
  color: var(--color-grey-dark-2);

  ${mediaQuery[0]} {
    font-size: 52px;
  }
`

const Notice = styled.span`
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 15px;
  text-align: center;
  color: #606060;
  ${mediaQuery[0]} {
    margin-bottom: 100px;
    font-size: 20px;
  }
`

const ContentSetWrapper = styled(Box)`
  margin-bottom: 52px;

  ${mediaQuery[0]} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    margin-bottom: 44px;
  }

  &:nth-of-type(2n) {
    ${mediaQuery[0]} {
      flex-direction: row-reverse;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const TagBox = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #c5b89c;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  padding: 2px 12px;
  margin-top: 16px;
  margin-bottom: 8px;

  ${mediaQuery[0]} {
    padding: 6px 12px;
    margin-top: 26px;
    margin-bottom: 24px;
  }

  span {
    font-size: 12px;
    font-weight: 700;
    color: var(--color-white);

    ${mediaQuery[0]} {
      font-size: 14px;
    }
  }
`

const ContentImageWrapper = styled(Box)`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1.73;

  ${mediaQuery[0]} {
    flex-shrink: 1;
    width: 558px;
    height: 322px;
    aspect-ratio: unset;
  }
`

const ContentTextWrapper = styled(Box)`
  width: 100%;
  ${mediaQuery[0]} {
    width: 594px;
  }
`

const ContentTitle = styled(Typography)`
  font-size: 16px;
  line-height: 1.4em;
  font-weight: 700;
  color: var(--color-grey-dark-2);
  ${mediaQuery[0]} {
    font-size: 24px;
  }

  br.pc-only {
    display: none;
    ${mediaQuery[0]} {
      display: block;
    }
  }
`

const DoubleQuoteImageWrapper = styled(Box)`
  position: relative;
  width: 12px;
  aspect-ratio: 1;
  margin-top: 14px;
  ${mediaQuery[0]} {
    width: 16px;
    margin-top: 24px;
  }
`

const ContentDescLists = styled.ul`
  li {
    display: flex;
    list-style-type: none;
    font-weight: 400;
    font-size: 16px;
    word-break: keep-all;
    word-wrap: break-word;
    line-height: 1.4em;
    color: #555555;
    margin-top: 15px;

    &:before {
      content: '';
      display: inline-block;
      flex-shrink: 0;
      width: 5px;
      height: 5px;
      margin-right: 10px;
      transform: translateY(100%);
      background-color: #555555;
      border-radius: 50%;
    }
  }
`

const DoubleQuoteImage = () => (
  <DoubleQuoteImageWrapper>
    <img src={DoubleQuote} alt={'double quote'} />
  </DoubleQuoteImageWrapper>
)

const SectionImage = ({ src }: { src: string }) => (
  <ContentImageWrapper>
    <Box
      sx={{
        borderRadius: '12px',
        width: '100%',
        height: '100%',
        background: `url(${src}) no-repeat center / cover`,
      }}
    />
  </ContentImageWrapper>
)

const ServicePoints = () => (
  <Section sx={{ backgroundColor: '#FAFBFD', textAlign: ' left' }}>
    <CenterAlignerWithXPadding>
      <SubTitle>Service</SubTitle>
      <SectionTitle>현직 IT전문가가 제공하는 현실적인 조언</SectionTitle>
      <Notice>*Professional 상품 기준</Notice>
      <Box>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint01} />
          <ContentTextWrapper>
            <TagBox>
              <span>1</span>
            </TagBox>
            <ContentTitle>IT 서비스 및 비즈니스 아이템 분석</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>
                스타트업의 비즈니스 아이템과 목표를 분석하고, IT 서비스 (웹 or 앱) 구축에 필요한 요구사항을 파악해요.
              </li>
              <li>비즈니스 모델과 IT 서비스의 목표가 상충되지는 않는지 확인해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper className="pc-reverse">
          <SectionImage src={ServicePoint02} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>2</span>
              </Typography>
            </TagBox>
            <ContentTitle>외주 개발 견적 및 필요 인력 정의</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>외주 개발에 대한 견적을 수립하고, 필요한 인력과 역할을 정의해요.</li>
              <li>프로젝트 일정과 예산을 고려하여 기능의 우선순위를 정의해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint03} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>3</span>
              </Typography>
            </TagBox>
            <ContentTitle>작업 프로세스 정의</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>IT 서비스 구축에 필요한 작업 프로세스를 정의하고, 업무 흐름을 최적화해요.</li>
              <li>개발, 테스트, 배포 등의 단계별 업무를 구체화하고 프로젝트 관리 방법을 제안해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint04} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>4</span>
              </Typography>
            </TagBox>
            <ContentTitle>서비스 개발 플랫폼 상담</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>웹, 앱, 네이티브 등의 서비스 플랫폼에 대해 상세히 설명하고, 각각의 장단점과 적합성을 평가해요.</li>
              <li>비즈니스 모델과 목표에 맞는 서비스 플랫폼을 추천해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint05} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>5</span>
              </Typography>
            </TagBox>
            <ContentTitle>매출 포인트와 인프라 포인트 구분</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>
                IT 서비스에서의 매출 포인트와 인프라 포인트를 구분하여 비즈니스 모델을 분석하고, 수익 모델을 제안해요.
              </li>
              <li>수익 창출을 위한 최적의 전략과 방안을 고려하여 매출 포인트와 인프라 포인트를 최적화해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint06} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>6</span>
              </Typography>
            </TagBox>
            <ContentTitle>기술 선택과 최적화</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>최신 기술 동향을 분석하고, 스타트업의 요구사항에 가장 적합한 기술 스택을 추천해요.</li>
              <li>기술적인 측면에서의 성능 최적화와 확장성을 고려하여 개발 방향을 안내해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint07} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>7</span>
              </Typography>
            </TagBox>
            <ContentTitle>개발자 컨텍 및 기타 고민 해결</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>개발 팀 구축에 대한 조언과 도움을 제공해요.</li>
              <li>IT 서비스 개발과 관련된 기타 고민 사항에 대한 질의응답을 수행하고 해결 방안을 제시해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint08} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>8</span>
              </Typography>
            </TagBox>
            <ContentTitle>보안 및 데이터 관리 전략</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>개인정보 보호와 데이터 보안을 고려한 IT 서비스 개발 전략을 수립해요.</li>
              <li>데이터 수집, 저장, 처리, 백업 등의 관리 방법을 제안하고 보안 위협에 대비하는 전략을 마련해요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
        <ContentSetWrapper>
          <SectionImage src={ServicePoint09} />
          <ContentTextWrapper>
            <TagBox>
              <Typography>
                <span>9</span>
              </Typography>
            </TagBox>
            <ContentTitle>사용자 경험 (UX) 디자인 컨설팅</ContentTitle>
            <DoubleQuoteImage />
            <ContentDescLists>
              <li>사용자 중심의 디자인 접근법을 통해 웹 또는 앱의 사용자 경험을 개선하는 방법을 제안해요.</li>
              <li>UI/UX 설계 원칙과 가이드라인에 따라 사용자 친화적인 인터페이스를 설계하는데 도움을 드려요.</li>
            </ContentDescLists>
          </ContentTextWrapper>
        </ContentSetWrapper>
      </Box>
    </CenterAlignerWithXPadding>
  </Section>
)

export default ServicePoints
