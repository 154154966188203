import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { mediaQuery } from '../../theme/breakpoints'
import CenterAlignerWithXPadding from '../common/CenterAlignerWithXPadding'
import Section from '../common/Section'
import { Link } from 'react-router-dom'

const Title = styled(Typography)`
  font-size: 22px;
  text-align: center;
  color: var(--color-white);
  word-wrap: break-word;
  word-break: keep-all;
  line-height: 1.4em;
  ${mediaQuery[0]} {
    font-size: 32px;
  }
`

const Buttons = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 35px;
`

const ToLookButton = styled(Box)`
  display: inline-block;
  padding: 12px 28px;
  border-radius: 25px;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;

  ${mediaQuery[0]} {
    padding: 14px 32px;
    margin-top: 70px;
    border-radius: 25px;
    font-size: 16px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`

const ToApplyButton = styled(ToLookButton)`
  background-color: #c5b89c;
  ${mediaQuery[0]} {
    &:hover {
      background-color: #9f9681;
    }
  }
`

const EndBanner = () => {
  return (
    <>
      <Section sx={{ background: 'var(--color-grey-dark-2)' }}>
        <CenterAlignerWithXPadding>
          <Title>IT 비즈니스의 시작, InnoPM이 함께해요.</Title>
        </CenterAlignerWithXPadding>
        <Buttons>
          <ToLookButton>
            <Link to="https://www.onboarders.co.kr/course ">온보더즈 IT코스 보러가기</Link>
          </ToLookButton>
          <ToApplyButton>
            <Link to="/consultationApplication">컨설팅 신청 바로가기</Link>
          </ToApplyButton>
        </Buttons>
      </Section>
    </>
  )
}

export default EndBanner
